@import "w3";


.page-content {
    @extend .w3-container;
    @extend .w3-padding-16;
    padding-left: 20%;
    padding-right: 20%;
}


.card {
    @extend .w3-card-4;
    @extend .w3-card-4;
    @extend .w3-center;
    @extend .w3-blue ; 
}

.banner-image {
    @extend .w3-container;
    @extend .w3-padding-16;
}



@media screen and (max-width: 600px) {
    .page-content {
        @extend .w3-container;
        @extend .w3-padding-16;
        padding-left: 5%;
        padding-right: 5%;
    }
  }
  

